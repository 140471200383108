import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import header2 from "../../assets/header2.png";
import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">Tenha de Volta uma Vida Plena e Saudável</h1>

          <p className="text">
            Somos Especialistas em Tratamento de Dependência Química e
            Transtornos Mentais, com métodos eficazes, personalizados e de forma
            humanizada.
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <figure className="left">
            <img src={header2} alt="Mãos juntas" />
          </figure>

          <div className="right">
            <h2 className="title">Quem Somos?</h2>

            <figure className="mobile">
              <img src={header2} alt="Mãos juntas" />
            </figure>

            <p className="text">
              <b>
                Somos o Grupo Despertar, uma rede de clínicas credenciadas
                no tratamento da dependência química e transtornos mentais.
              </b>
              <br />
              <br />
              Atuamos com um trabalho sério, responsável e com todo o suporte
              que um tratamento exige. Temos parcerias com unidades de
              tratamento responsáveis e legalizados para poder oferecer a cada
              paciente um tratamento digno e que conscientize a volta para o
              seio familiar e membro produtivo da sociedade.
            </p>

            <CtaWpp />
          </div>
        </article>
      </section>

      <section className="header3">
        <article className="container vertical">
          <h2 className="title">Nossa Metodologia</h2>

          <p className="text">
            <b>
              Contamos com profissionais experientes e comprometidos com
              recuperação ágil e saudável de nossos pacientes.
            </b>
          </p>

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={header3_1} alt="Conscientização" />
              </figure>

              <p className="item_title">Conscientização</p>

              <p className="item_text">
                A conscientização no tratamento de pessoas com dependência
                química e transtornos mentais é crucial para promover empatia,
                reduzir o estigma e garantir acesso a cuidados adequados,
                personalizados e contínuos. Educação e apoio comunitário são
                essenciais para facilitar a recuperação e reintegração social
                desses indivíduos.
              </p>

              <CtaWpp text={"Fale conosco"} />
            </div>

            <div className="item">
              <figure className="top">
                <img
                  src={header3_2}
                  alt="Mudança de comportamentos e hábitos"
                />
              </figure>

              <p className="item_title">Mudança de comportamentos e hábitos</p>

              <p className="item_text">
                O tratamento para mudança de comportamentos e hábitos em
                dependência química e transtornos mentais utiliza terapias
                cognitivo-comportamentais e intervenções motivacionais,
                promovendo novos hábitos saudáveis e sustentáveis. O suporte
                contínuo e a integração familiar são essenciais para o sucesso.
              </p>

              <CtaWpp text={"Fale conosco"} />
            </div>

            <div className="item">
              <figure className="top">
                <img
                  src={header3_3}
                  alt="Trabalho dos 12 passos de Narcóticos Anônimos e Alcoólicos Anônimos"
                />
              </figure>

              <p className="item_title">
                Trabalho dos 12 passos de Narcóticos Anônimos e Alcoólicos
                Anônimos
              </p>

              <p className="item_text">
                O programa de 12 passos de Narcóticos Anônimos e Alcoólicos
                Anônimos oferece um modelo estruturado de recuperação que inclui
                autoavaliação, aceitação, e desenvolvimento espiritual. O
                suporte em grupo e a orientação de um padrinho são essenciais
                para manter a sobriedade a longo prazo.
              </p>

              <CtaWpp text={"Fale conosco"} />
            </div>
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
